<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <bookmarks />
    </div>

    <!-- Right Col -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <locale class="lang" />
      <dark-Toggler class="d-none d-lg-block" />
      <search-bar />
      <cart-dropdown />
      <notification-dropdown />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
  import { BLink, BNavbarNav } from 'bootstrap-vue';
  import Bookmarks from './components/Bookmarks.vue';
  import Locale from './components/Locale.vue';
  import DarkToggler from './components/DarkToggler.vue';
  import SearchBar from './components/SearchBar.vue';
  import CartDropdown from './components/CartDropdown.vue';
  import NotificationDropdown from './components/NotificationDropdown.vue';
  import UserDropdown from './components/UserDropdown.vue';

  export default {
    components: {
      BLink,

      // Navbar Components
      BNavbarNav,
      Bookmarks,
      Locale,
      DarkToggler,
      SearchBar,
      CartDropdown,
      NotificationDropdown,
      UserDropdown,
    },
    props: {
      toggleVerticalMenuActive: {
        type: Function,
        default: () => {},
      },
    },
  };
</script>
